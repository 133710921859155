import React, { lazy, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { Navbar, NavbarBrand } from "reactstrap";
import "./styles/App.css";
import "./styles/FontStyles.css";
import "./styles/Body.css";
import "./components/layout/top/NavTop.css";
import { loginRequest } from "./auth/msal/authConfig";
import ButtonColor from "@helpers/ButtonColor";
import { useAuth } from "@msal/AuthProvider";
const App = lazy(() => import("App"));
const AppLogin: React.FC = () => {
     const { getToken } = useAuth();
     const [token, setToken] = React.useState<string | null>(null);
     const [loading, setLoading] = React.useState<boolean>(true);
     const [loginError, setLoginError] = React.useState<string | null>(null);
     useEffect(() => {
          const interval = setInterval(() => {
               if (token === null) {
                    setToken(getToken());
                    clearInterval(interval);
                    setLoading(false);
               }
          }, 1000);
          return () => clearInterval(interval);
     }, [token, getToken]);
     const { instance } = useMsal();
     const handleLogin = () => {
          setLoginError(null);
          instance
               .loginRedirect(loginRequest)
               .then((response: any) => {
                    console.log("login response", response);
                    instance.setActiveAccount(response.account);
                    window.location.reload();
               })
               .catch((error) => {
                    setLoginError(error.message);
                    console.log("login error", error);
               });
     };
     if (getToken() !== null) {
          return <App />;
     } else {
          return (
               <React.Fragment>
                    <Navbar id="NavTop" className="NoPrint navbar-expand-lg navbar-toggleable-md ng-white border-bottom box-shadow mb-3" light>
                         <img className="navbar-logo" alt="Crossroads Logo" src={`${process.env.PUBLIC_URL}/images/1024CrossroadsLogoWhite.png`} />
                         <NavbarBrand style={{ whiteSpace: "nowrap" }}>Clinical Reports</NavbarBrand>
                         <div style={{ marginTop: "5px" }}>{!loading && <ButtonColor Label="Login" onClick={handleLogin} TextColor="Green" Size="large" />}</div>
                    </Navbar>
                    {loginError && (
                         <div style={{ margin: "60px" }}>
                              <div style={{ fontSize: "20px", color: "red" }}>Login Error: {loginError}</div>
                              <div style={{ fontSize: "20px", color: "black" }}>Refresh Page...</div>
                         </div>
                    )}
               </React.Fragment>
          );
     }
};
export default AppLogin;
