// src/auth/AuthProvider.tsx

import React, { ReactNode, createContext, useContext, useEffect, useRef } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import moment from "moment";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext<{ getToken: () => string | null }>({ getToken: () => null });
interface AuthProviderProps {
     children: ReactNode;
}
export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
     const { instance, accounts } = useMsal();
     const isAuthenticated = useIsAuthenticated();
     const tokenRef = useRef<string | null>(null);

     useEffect(() => {
          const requestToken = async () => {
               const IsTokenExpired = (token: string | null): boolean => {
                    if (!token) return true;
                    const decodedToken = jwtDecode(token);
                    if (!decodedToken.exp) return true;
                    return moment.unix(decodedToken.exp).add(-4, "minute").isBefore(moment()); //4 minutes before expiration
               };
               if (isAuthenticated && accounts.length > 0 && IsTokenExpired(tokenRef.current)) {
                    try {
                         const response = await instance.acquireTokenSilent({
                              scopes: ["api://86baa635-f7b4-43bf-964a-165cd1964713/tasks.read"],
                              account: accounts[0],
                         });
                         tokenRef.current = response.accessToken;
                         const decodedToken = jwtDecode(response.accessToken);
                         let interval: number = moment.unix(decodedToken.exp!).add(-3, "minute").diff(moment(), "milliseconds");
                         if (interval < 0) interval = 1 * 60 * 1000;
                         console.info(
                              moment().format("h:mm:ssa"),
                              "Access Token Acquired:",
                              "." + response.accessToken.slice(-5),
                              "Expires in (min):",
                              moment.unix(decodedToken.exp!).diff(moment(), "minutes"),
                              "Refreshes at:",
                              moment.unix(decodedToken.exp!).add(-4, "minute").format("h:mma")
                         );
                    } catch (error) {
                         if (error instanceof InteractionRequiredAuthError) {
                              instance.acquireTokenRedirect({
                                   scopes: ["api://86baa635-f7b4-43bf-964a-165cd1964713/tasks.read"],
                                   account: accounts[0],
                              });
                         } else {
                              console.error("Token acquisition failed:", error);
                         }
                    }
               }
          };
          requestToken();
          const interval = setInterval(() => {
               requestToken();
          }, 1 * 60 * 1000); //check token every minute
          return () => clearInterval(interval);
     }, [isAuthenticated, accounts, instance]);

     const getToken = (): string | null => tokenRef.current;
     return <AuthContext.Provider value={{ getToken }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
