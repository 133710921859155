import { Button, ThemeProvider, createTheme } from "@mui/material";
import React from "react";

interface PropsType {
     Label: string;
     className?: string;
     onClick: (e: any) => void;
     TextColor?: string;
     BackGroundColor?: string;
     HoverTextColor?: string;
     HoverBackGroundColor?: string;
     disabled?: boolean;
     Size?: "xs" | "small" | "medium" | "large" | "xl";
     WrapText?: boolean;
}
function FontSize(Size: "xs" | "small" | "medium" | "large" | "xl") {
     switch (Size) {
          case "xs":
               return "0.5rem";
          case "small":
               return "0.75rem";
          case "medium":
               return "1rem";
          case "large":
               return "1.25rem";
          case "xl":
               return "1.5rem";
     }
}
export default function ButtonColor(props: PropsType) {
     const theme = createTheme({
          components: {
               MuiButton: {
                    styleOverrides: {
                         // Name of the slot
                         root: {
                              // Some CSS
                              margin: "0 2px 5px 2px",
                              padding: "1px 5px 0 5px",
                              border: "1px solid var(--primarycolor)",
                              borderRadius: "0.5rem",
                              textTransform: "none",
                              transitionDuration: "0.4s",
                              boxShadow: "0 8px 16px 0 var(--secondarycolordim), 0 3px 6px 0 var(--secondarycolordim)",
                              "&:hover": {
                                   color: (props.HoverTextColor ? props.HoverTextColor : "yellow") + " !important",
                                   backgroundColor: (props.HoverBackGroundColor ? props.HoverBackGroundColor : "black") + " !important",
                              },
                         },
                    },
               },
          },
     });
     return (
          <ThemeProvider theme={theme}>
               <Button
                    style={{
                         color: props.TextColor ? props.TextColor : "cadetblue",
                         backgroundColor: props.BackGroundColor ? props.BackGroundColor : "white",
                         fontSize: FontSize(props.Size ? props.Size : "medium"),
                         whiteSpace: props.WrapText ? "normal" : "nowrap",
                    }}
                    className={props.className}
                    variant="outlined"
                    onClick={props.onClick}
                    disabled={props.disabled ? props.disabled : false}
               >
                    {props.Label}
               </Button>
          </ThemeProvider>
     );
}
