import "bootstrap/dist/css/bootstrap.css";
import { createRoot } from "react-dom/client";
import React from "react";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "@msal/authConfig";
import { AuthProvider } from "@msal/AuthProvider";
import AppLogin from "AppLogin";
const container = document.getElementById("root");
// @ts-ignore
const root = createRoot(container);
if (!container) {
     throw new Error("Could not find root element.");
}
const msalInstance = new PublicClientApplication(msalConfig);
const AuthComponent = () => {
     return (
          <MsalProvider instance={msalInstance}>
               <AuthProvider>
                    <AppLogin />
               </AuthProvider>
          </MsalProvider>
     );
};
root.render(
     <MsalProvider instance={msalInstance}>
          <AuthComponent />
     </MsalProvider>
);
