export const msalConfig = {
     auth: {
          clientId: "fc11f388-3ab3-41cc-9226-6d6ed490dd7e",
          authority: "https://login.microsoftonline.com/4dccbfde-9ec8-47d3-ad4a-bd7739ed3e21",
          redirectUri: document.getElementById("root")?.baseURI,
     },
     cache: {
          cacheLocation: "localStorage", // This configures MSAL to use local storage, this will remember the user's login session is chrome is closed
          storeAuthStateInCookie: false, // Set this to true if you're facing issues on IE11 or Edge
     },
};

export const loginRequest = {
     scopes: ["api://86baa635-f7b4-43bf-964a-165cd1964713/tasks.read"],
};

export const tokenRequest = {
     scopes: ["api://86baa635-f7b4-43bf-964a-165cd1964713/tasks.read"],
};
